<template>
  <div
    class="modal fade"
    tabindex="-10"
    id="modal_errori_raas"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Errori</h5>
        </div>
        <template v-if="!loaded">
          <div class="modal-body">
            <div class="mx-auto my-auto text-center">
              <div class="spinner-border text-gray-600" role="status"></div>
              <span class="text-gray-600 ps-3 fs-2">Loading</span>
            </div>
          </div>
          <div class="modal-footer"></div>
        </template>
        <div
          v-else-if="results && results.length === 0 && loaded"
          class="mx-auto my-auto text-center pt-5 mt-5 mb-5 pb-5"
        >
          <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
          <span class="text-gray-700 ps-3 fs-2">
            Attenzione! Nessun risultato.
          </span>
        </div>
        <div
          v-else-if="status != 200 && status != 204"
          class="mx-auto my-auto text-center pt-5 mt-5 mb-5 pb-5"
        >
          <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
          <span class="text-gray-700 ps-3 fs-2">
            Attenzione! Si è verificato un errore. Riprovare più tardi.
          </span>
        </div>
        <template v-else>
          <div class="modal-body" v-if="link != 'RAAS_ERR_TESSERAMENTI'">
            <div class="card-body p-6">
              <ul>
                <li v-for="(err, index) in results" :key="index">
                  {{ err.errore }}
                </li>
              </ul>
            </div>
          </div>
          <div class="modal-body" v-else>
            <div class="card-body p-6">
              <Datatable
                :table-header="tableHeader"
                :table-data="results"
                :total="record"
                :current-page="currentPage"
                :rows-per-page="fetchRows"
                :sortLabel="sortColumn"
                :order="sortOrder"
                @sort="setSortOrderColumn"
                @items-per-page-change="setFetchRowsAffiliazioni"
                @current-change="setCurrentPageAffiliazioni"
              >
                <template v-slot:cell-errore="{ row: data }"
                  ><div class="tab-long">
                    {{ data.errore }}
                  </div>
                </template>
              </Datatable>
            </div>
          </div>

          <div class="modal-footer">
            <span
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="modalEsporta"
            >
              Chiudi
            </span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Datatable from "@/components/kt-datatable/KTDatatable.vue";

import { useStore } from "vuex";
import { computed, watch, getCurrentInstance, ref } from "vue";
export default {
  name: "ErroriRaas",
  props: {
    id_societa: {
      type: [Number, String],
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
  components: {
    Datatable,
  },
  setup(props) {
    const store = useStore();
    const anno = computed(() => store.getters.stagioneSelected);
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const rowsToSkip = ref(0);
    const fetchRows = ref(25);
    const currentPage = ref(1);
    const getErrorsFromIdSoc = () => {
      const localKeys = {
        anno: anno.value,
        id_societa: props.id_societa,
        rowsToSkip: rowsToSkip.value,
        fetchRows: fetchRows.value,
      };
      store.dispatch("setStoreListData", {
        keys: localKeys,
        apiLink: globalApi[props.link],
        itemName: "raas_affiliazioni_errori_list",
        version: props.link == "RAAS_ERR_TESSERAMENTI" ? 2 : null,
      });
    };

    const tableHeader = ref([
      {
        name: "Errore",
        key: "errore",
        sortable: false,
      },
    ]);

    watch(
      () => props.id_societa,
      () => {
        getErrorsFromIdSoc();
      }
    );

    const setFetchRowsAffiliazioni = (value) => {
      currentPage.value = 1;
      rowsToSkip.value = 0;
      fetchRows.value = value;
      getErrorsFromIdSoc();
    };
    const setCurrentPageAffiliazioni = (value) => {
      currentPage.value = value;
      rowsToSkip.value = parseInt((currentPage.value - 1) * fetchRows.value);
      getErrorsFromIdSoc();
    };
    return {
      setFetchRowsAffiliazioni,
      setCurrentPageAffiliazioni,
      tableHeader,
      results: computed(() =>
        store.getters.getStateFromName("resultsraas_affiliazioni_errori_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedraas_affiliazioni_errori_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordraas_affiliazioni_errori_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusraas_affiliazioni_errori_list")
      ),
      currentPage,
      rowsToSkip,
      fetchRows,
    };
  },
};
</script>

<style scoped>
.modal-content {
  width: 59rem;
}
</style>
