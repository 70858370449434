// COMPOSABLE
// IMPORT LIBRERIE VARIE
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { addRichiesta, addRichiestaV2 } from "@/requests/raasAddRichiesta";
import { alertFailed, alertSuccess } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export function useAddRaas(listName, id_tipologia) {
  // DICHIARAZIONE VARIABILI
  //  02/09/2024
  // PER CAMBIAMENTI FUNRTION RAAS, INDIVIDUARE L'ID TIPOLOGIA, E PER QUELL'ID UTILIZZARE LA V2 CHE FARà MICHELE
  // DA CHIEDERE SE SI PUO RENDERE GENERICO PER TUTTI I RAAS
  const refreshContent = ref(0);
  const isSendingRaas = ref(false);
  const store = useStore();
  const results = computed(() =>
    store.getters.getStateFromName(`results${listName}`)
  );
  const anno = computed(() => store.getters.stagioneSelected);

  const sendRaasSelezionati = async (stati_invio) => {
    const societaDaInviare = ref([]);
    results.value.forEach((element) => {
      if (element.selected) {
        societaDaInviare.value.push({ id_societa: element.id_societa });
      }
    });
    // && id_tipologia != ?
    if (!societaDaInviare.value.length) {
      alertFailed("Nessuna società selezionata");
      return;
    }

    Swal.fire({
      title: "Sei Sicuro?",
      text: "Procedendo gli elementi selezionati verranno inviati",
      icon: "warning",
      buttonsStyling: false,
      showCancelButton: true,
      cancelButtonText: "Annulla",
      confirmButtonText: "Invia",
      allowOutsideClick: false,
      customClass: {
        confirmButton: "btn fw-bold btn-light-success",
        cancelButton: "btn fw-bold btn-light-warning",
      },
    }).then(async (e) => {
      if (e.isDismissed) {
        return;
      }
      if (e.isConfirmed) {
        // se selezionati
        // sendRaas(societaDaInviare.value);
        // se tutti
        sendRaasV2(societaDaInviare.value, stati_invio);
      }
    });
  };

  const sendRaasTutti = async (stati_invio) => {
    // if (!results.value.length) alertFailed("Impossibile inviare la richiesta");
    // else if (results.value.length > 10) {
    //   alertFailed("Selezionare al massimo 10 elementi e riprovare");
    //   return;
    // } else

    Swal.fire({
      title: "Sei Sicuro?",
      html: `${
        stati_invio == null ||
        stati_invio.includes("2") ||
        stati_invio.includes("3")
          ? `Per procedere all'invio per gli stati '<b>Correttamente inviata</b>' e '<b>Errori su invio</b>',<br> è necessario forzare l'invio utilizzando il flag indicato di seguito.<br>`
          : ""
      } Procedendo verranno inviati gli elementi con i seguenti status: <ul style="max-width: 15rem;margin-right: auto;
    margin-left: auto;"><br>${
      stati_invio.includes("1") ? "<li><b>Da inviare</b></li>" : ""
    } ${
        stati_invio.includes("2") ? "<li><b>Correttamente Inviata</b></li>" : ""
      } ${stati_invio.includes("3") ? "<li><b>Errori su invio</b></li>" : ""} ${
        stati_invio.includes("4") ? "<li><b>Da reinviare</b></li>" : ""
      }</ul>`,

      input: `${
        stati_invio == null ||
        stati_invio.includes("2") ||
        stati_invio.includes("3")
          ? "checkbox"
          : ""
      }`,
      inputPlaceholder: `Forza invio Raas`,
      inputValidator: (result) => {
        return (
          ((stati_invio == null && !result) ||
            (stati_invio.includes("2") && !result) ||
            (stati_invio.includes("3") && !result)) &&
          "Obbligatorio"
        );
      },

      icon: "warning",
      buttonsStyling: false,
      showCancelButton: true,
      cancelButtonText: "Annulla",
      confirmButtonText: "Invia",
      allowOutsideClick: false,
      customClass: {
        confirmButton: "btn fw-bold btn-light-success",
        cancelButton: "btn fw-bold btn-light-warning",
      },
    }).then(async (e) => {
      // const arraySocietaDaInviare = ref([]);
      if (e.isDismissed) {
        return;
      }
      if (e.isConfirmed) {
        // results.value.forEach((element) => {
        //   if (element.id_stato != 2)
        //     arraySocietaDaInviare.value.push(element);
        // });
        // await sendRaas(arraySocietaDaInviare.value);
        sendRaasV2(null, stati_invio);
      }
    });
  };

  const sendRaasV2 = async (societaArray = null, stati_invio) => {
    isSendingRaas.value = true;
    await addRichiestaV2(
      id_tipologia,
      anno.value,
      societaArray,
      stati_invio
    ).then((res) => {
      if (res.data.result === "OK") {
        alertSuccess("Richiesta inviata con successo");
        refreshContent.value = refreshContent.value + 1;
      } else {
        alertFailed(
          "Non è possibile inviare la richiesta. Riprovare più tardi"
        );
      }
      isSendingRaas.value = false;
    });
  };

  return {
    sendRaasSelezionati,
    sendRaasTutti,
    refreshContent,
    isSendingRaas,
    sendRaasV2,
  };
}
