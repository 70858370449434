import services from "../axios/dbManag";

export const addRichiesta = (id_tipologia, anno, array_societa) => {
  return services.dbManag
    .post("/raas/richiesta/add", {
      id_tipologia,
      anno,
      array_societa,
    })
    .then(async (res) => {
      return await richiesteList(id_tipologia, anno, true).then(
        async (response) => {
          if (response.status === 200) {
            if (id_tipologia != 8)
              await richiesteList(id_tipologia, anno, false).then((response) =>
                console.log(response)
              );
            return res;
          } else return response;
        }
      );
    })
    .catch((e) => {
      return e.response;
    });
};

export const addRichiestaV2 = (
  id_tipologia,
  anno,
  array_societa,
  stati_invio
) => {
  console.log({
    id_tipologia,
    anno,
    json_societa: array_societa ? JSON.stringify(array_societa) : "",
    stato_invio: stati_invio,
  });
  // return;
  return services.dbManagV2
    .post("/raas/richiesta/add", {
      id_tipologia,
      anno,
      json_societa: JSON.stringify(array_societa),
      stato_invio: stati_invio,
    })
    .then(async (res) => {
      return await richiesteList(id_tipologia, anno, true).then(
        async (response) => {
          if (response.status === 200) {
            if (id_tipologia != 8)
              await richiesteList(id_tipologia, anno, false).then((response) =>
                console.log(response)
              );
            return res;
          } else return response;
        }
      );
    })
    .catch((e) => {
      return e.response;
    });
};

export const richiesteList = (id_tipologia, anno, isId_stato) => {
  return services.dbManag
    .post("/raas/richieste/dettaglio/list", {
      id_tipologia,
      id_stato: isId_stato ? 0 : null,
      id_stato_rssd: !isId_stato ? 0 : null,
      anno,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const getItTime = (dateUtc) => {
  if (dateUtc === "-") return;
  const replaceAt = (string, index, replacement) => {
    if (index >= string.length) {
      return string.valueOf();
    }

    var chars = string.split("");
    chars[index] = replacement;
    return chars.join("");
  };
  // dateUtc = "31/12/2023 23:06:47";
  const dayUtc = dateUtc.substring(0, 2);
  const monthUtc = dateUtc.substring(3, 5);
  let formattedDateUtc = dateUtc;
  formattedDateUtc = replaceAt(formattedDateUtc, 0, monthUtc[0]);
  formattedDateUtc = replaceAt(formattedDateUtc, 1, monthUtc[1]);
  formattedDateUtc = replaceAt(formattedDateUtc, 3, dayUtc[0]);
  formattedDateUtc = replaceAt(formattedDateUtc, 4, dayUtc[1]);
  const dateLocal = new Date(formattedDateUtc);
  // dateLocal.setHours()
  const hours = dateLocal.getHours();
  dateLocal.setHours(hours + 1);
  // return dateUtc.replace(oldDateHours, oldDateHours + 1);
  const dateLocalday = dateLocal.getDate();
  const dateLocalmonth = dateLocal.getMonth() + 1;
  const dateLocalyear = dateLocal.getFullYear();
  const dateLocalhours = dateLocal.getHours();
  const dateLocalminutes = dateLocal.getMinutes();
  const dateLocalseconds = dateLocal.getSeconds();
  const setZeroBeforeNumber = (number) => {
    return number < 10 ? "0" + number : number;
  };
  return `${setZeroBeforeNumber(dateLocalday)}/${setZeroBeforeNumber(
    dateLocalmonth
  )}/${dateLocalyear} ${setZeroBeforeNumber(
    dateLocalhours
  )}:${setZeroBeforeNumber(dateLocalminutes)}:${setZeroBeforeNumber(
    dateLocalseconds
  )}`;
  // return `${formattedDateUtc}`;
};
